<template>
    <div v-if="data != null">
        <a-modal v-model="visible" :title="data.title" :width="formJson.config.width > 800 ? formJson.config.width : 800"
            @cancel="close()" :okButtonProps="{ text: '确认' }" centered :footer="null" destroyOnClose>
            <a-tabs defaultActiveKey="form">
                <a-tab-pane key="form" tab="表单">
                    <k-form-build ref="kfb" :value="formJson" />
                    <attach-upload :attachment="data.attachment"></attach-upload>
                    <div>
                        <examine ref="exmines" @focus="examines" :formData="Data.formData" :flowId="this.flowId">
                        </examine>
                    </div>
                    <div class="footer" v-if="data.status == '退回' && data.isAuth">
                        <div class="button">
                            <a-button type="primary" @click="doValidate()">确认提交</a-button>
                            <a-button @click="close()">取消关闭</a-button>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane style="padding-left:20px" key="process" tab="办理记录">
                    <a-timeline>
                        <a-timeline-item v-if="data.status != '已结束'">
                            <a-icon slot="dot" type="loading"></a-icon>
                            <div class="process">
                                <div class="title">{{ data.flow.name }}</div>
                                <div>
                                    <span>待处理</span>
                                </div>
                            </div>
                        </a-timeline-item>
                        <div v-for="(item, index) in data.process" :key="index">
                            <a-timeline-item color="green">
                                <div class="process">
                                    <div class="title">{{ item.flowName }}</div>
                                    <div v-if="!item.isRoot">
                                        <span v-if="item.content">{{ item.content }}</span>
                                        <span v-else>无会签内容</span>
                                        <attach-view :attachment="item.attachment"></attach-view>
                                    </div>
                                    <div class="times">
                                        <span>经办人：{{ item.staffName }}</span>
                                        <span>办理时间：{{ item.stopTime }}</span>
                                        <span v-if="!item.isRoot">耗时：{{ utils.getTimeStr(item.time) }}</span>
                                    </div>
                                </div>
                            </a-timeline-item>

                        </div>
                    </a-timeline>
                </a-tab-pane>
            </a-tabs>


        </a-modal>

    </div>
</template>

<script>
import attachView from '@/components/common/attach-view.vue';
import attachUpload from '@/components/common/attach-upload.vue';
import formUtils from '@/common/formUtils';
import utils from '@/common/utils';
import request from '@/config/request';
import examine from './examine';
export default {
    components: { attachUpload, attachView, examine },
    data() {
        return {
            visible: false,
            data: null,
            formJson: {},
            formData: [],
            readList: [],
            hideList: [],
            flowData: {
                attachment: [],//附件
                content: ''//会签意见
            },
            backFlowId: '',
            showBackList: false,
            Data: {},
            flowId: ''
        };
    },
    methods: {
        examines() {
            this.$refs.kfb.getData().then(ret => {
                this.Data = {
                    applyId: this.data.applyId,
                    formData: JSON.stringify(formUtils.getData(ret, this.readList, this.hideList)),
                    attachment: this.flowData.attachment
                };
            });
        },
        show(data) {
            this.data = data;
            this.flowId = data.flowId;
            this.formJson = JSON.parse(data.formJson);
            this.formData = JSON.parse(data.formData);
            // console.log(this.formJson, "2333");
            this.visible = true;
            this.$nextTick(() => {
                this.initForm();
            });
        },
        // 提交
        doValidate() {
            this.$refs.kfb.getData().then(ret => {
                const jsonData = {
                    applyId: this.data.applyId,
                    formData: JSON.stringify(formUtils.getData(ret, this.readList, this.hideList)),
                    attachment: this.flowData.attachment
                };
                utils.showSpin();
                request.post('/platform/apply/rePost', jsonData).then(ret => {
                    this.close();
                    this.$emit('success');
                    utils.success('提交成功');
                });
            });
        },
        // 初始化表单的内容
        initForm() {
            //检查只读字段和隐藏字段
            const { readList, hideList } = formUtils.getFieldsRuleList(this.data.flow.formItems);
            if (hideList.length > 0) {
                this.$refs.kfb.hide(hideList);
            }
            if (readList.length > 0) {
                this.$refs.kfb.disable(readList);
            }
            //设置字段值
            this.$refs.kfb.setData(this.formData);
            //去掉必填项
            this.formJson = formUtils.checkDisable(this.formJson, readList, hideList);
            this.readList = readList;
            this.hideList = hideList;
        },
        close() {
            this.visible = false;
            this.backFlowId = '';
            this.flowData.content = '';
            this.flowData.attachment = [];
        }
    },
    props: {
        //是否显示底部提交按钮
        footer: {
            type: Boolean,
            default: () => false
        }
    }
};
</script>

<style lang="less" scoped>
.process {
    display: flex;
    flex-direction: column;

    .title {
        font-size: 14px !important;
    }

    .content {}

    .times {
        font-size: 12px;
        color: #999;

        >span {
            margin-right: 10px;
        }
    }
}

.footer {
    width: 100%;
    display: flex;
    flex-direction: column;

    >div,
    .button {
        width: 100%;

        >button {
            margin: 0 10px;
        }
    }

    .button {
        width: 100;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}
</style>