<template>
    <div id="ap">
        <template v-if="mode == 'form'">
            <a-card title="工作查询" :bordered="false">
                <a-form :model="param" ref="formRef" :rules="rules">
                    <a-row :gutter="12">
                        <a-col :span="6">
                            <a-form-model-item label="查询范围" prop="organId">
                                <a-select v-model="param.organId" placeholder="请选择查询范围" >
                                    <a-select-option v-for="item in organList" :key="item.organId"
                                        :value="item.organId">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row :gutter="12">
                        <a-col :span="6">
                            <a-form-model-item label="流程名称" prop="approveId">
                                <a-select v-model="param.approveId" placeholder="请选择流程">
                                    <a-select-option v-for="item in approveList" :key="item.approveId"
                                        :value="item.approveId">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="6">
                            <a-form-model-item label="创建日期" prop="startDate">
                                <a-range-picker :placeholder="['开始日期', '截止日期']" style="width: 100%"
                                    @change="setDateRange" />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row :gutter="12">

                    </a-row>
                    <a-row :gutter="12">
                        <a-col :span="6">
                            <a-form-model-item label="关键词" prop="keyword">
                                <a-input v-model="param.keyword" placeholder="请输入关键词" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="2">
                            <a-form-model-item label="状态" prop="status">
                                <a-select v-model="param.status">
                                    <a-select-option :value="null">全部</a-select-option>
                                    <a-select-option :value="10">已结束</a-select-option>
                                    <a-select-option :value="0">处理中</a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row><br></a-row>
                    <a-row :gutter="12">
                        <a-col :span="6">
                            <a-button-group>
                                <a-button type="primary" @click="doRefresh()" icon="search">查询</a-button>
                            </a-button-group>
                        </a-col>
                    </a-row>
                </a-form>
            </a-card>
        </template>
        <template v-if="mode == 'list'">
            <a-layout>
                <div class="content-header">
                    <a-affix :offset-top="0" style="width: 100%;">
                        <a-row type="flex" justify="space-between">
                            <a-col span="12">
                                <a-row :gutter="10">
                                    <a-col span="8">
                                <a-input v-model="param.keyword" allowClear search @pressEnter="doRefresh"
                                    placeholder="关键词查询" style="width: 100%;"
                                    @change="() => { if (param.keyword == '') { doRefresh(); } }"></a-input>
                            </a-col>
                            <a-col span="8">
                                <a-select style="width:100%" placeholder="分类筛选" v-model="param.categoryId" allowClear
                                    @change="doRefresh">
                                    <a-select-option :value="item.categoryId" v-for=" item  in  category "
                                        :key="item.categoryId">{{
            item.name }}</a-select-option>
                                </a-select>
                            </a-col>
                                </a-row>
                            </a-col>
                            
                            <a-col span="12">
                                <a-row type="flex" align="middle" justify="end" :gutter="3">
                                    <a-col span="6">
                                        <a-select mode="default" style="width:100%" placeholder="状态筛选"
                                            v-model="param.status" allowClear @change="doRefresh()">
                                            <a-select-option :value="null">全部</a-select-option>
                                            <a-select-option :value="-1">待发起人修改</a-select-option>
                                            <a-select-option :value="0">处理中</a-select-option>
                                            <a-select-option :value="10">审批结束</a-select-option>
                                        </a-select>
                                    </a-col>
                                    <a-col span="2">
                                        <a-button @click="() => { mode = 'form' }" style="width:100%">返回</a-button>
                                    </a-col>
                                </a-row>
                            </a-col>
                        </a-row>
                    </a-affix>
                </div>
            </a-layout>
            <a-layout>
                <a-layout-content>
                    <a-table :pagination="false" :style="{ background: '#FFF' }" size="small" row-key="applyId" border
                        :columns="keys" ref="list" :loading="loading" :data-source="data.records">
                        <!--标题-->
                        <template slot-scope="row" slot="apply-title">
                            <a @click="() => { isHander = false; getDetail(row); }">{{ row.title }}</a>
                        </template>
                        <!--当前审批节点-->
                        <template slot-scope="row" slot="current-flow">
                            <span v-if="row.status == '处理中'">{{ row.flowName }}</span>
                            <span v-else-if="row.status == '退回'">待发起人修改</span>
                            <span v-else>流程结束</span>
                        </template>
                        <!--状态-->
                        <template slot-scope="row" slot="status">
                            <a-tooltip title="流程已结束" v-if="row.status == '已结束'">
                                <a-icon style="color: #090" type="check-circle" />
                            </a-tooltip>
                            <a-tooltip title="正在办理中" v-else-if="row.status == '处理中'">
                                <a-icon type="clock-circle" style="color: rgb(0, 119, 255);" />
                            </a-tooltip>
                            <a-tooltip title="已退回,请申请人重新提交" v-else>
                                <a-icon type="info-circle" style="color: rgb(255, 145, 0);" />
                            </a-tooltip>
                        </template>

                    </a-table>
                </a-layout-content>
            </a-layout>
            <a-row>
                <a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
                    <a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
                        @showSizeChange="setLimit" :current="data.current" />
                </a-col>
            </a-row>
            <a-back-top />
        </template>

        <create-before ref="before" :category="category" @success="doRefresh"></create-before>
        <apply-detail ref="applyDetail" @success="doRefresh" :footer="isHander"></apply-detail>
        <create-form-model ref="createFormModel" @success="doRefresh"></create-form-model>
    </div>
</template>

<script>
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import request from '@/config/request';
import createBefore from './components/create-before.vue';
import applyDetail from './components/apply-detail.vue';
import createFormModel from './components/create-form-model.vue';
export default {
    components: { createBefore, applyDetail, createFormModel },
    data() {
        return {
            loading: false,
            mode: 'form',
            data: [],
            category: [],
            isHander: false,
            param: {
                page: 1,
                limit: 20,
                keyword: '',
                status: null,
                approveId: '',
                startDate: '',
                endDate: '',
                organId: apiUtil.getDefaultOrganId()
            },
            keys: [
                { title: 'ID', dataIndex: 'applyId', width: 80, align: 'center' },
                { title: '审批事项', scopedSlots: { customRender: 'apply-title' }, align: 'left', width: 300, ellipsis: true },
                { title: '发起人', dataIndex: 'userName', width: 120, align: 'center', ellipsis: true },
                { title: '发起时间', dataIndex: 'createTime', width: 160, align: 'center', ellipsis: true },
                { title: '当前审批节点', scopedSlots: { customRender: 'current-flow' }, width: 160, align: 'center', ellipsis: true },
                { title: '状态', scopedSlots: { customRender: 'status' }, width: 80, align: 'center', ellipsis: true },
            ],
            rules: {
                keyword:[{required:true,message:'请输入关键字',trigger:'blur'}]
            },
            approveList: [],
            organList: apiUtil.getAllOrganList()
        };
    },
    mounted() {
        this.getApproveList();
        this.getCategory();
    },
    methods: {
        ...apiUtil,
        getApproveList() {
            this.param.approveId = '';
            this.http
                .get('/platform/apply/queryApprove',{})
                .then(ret => {
                    this.approveList = ret.data;
                })
        },
        getDetail(apply) {
            utils.showSpin();
            request.get("/platform/apply/manager-detail/" + apply.applyId, {}).then(ret => {
                this.$refs.applyDetail.show(ret.data);
            });
        },
        getCategory() {
            request.get("/platform/approve/category", { organId: apiUtil.getDefaultOrganId() }).then(ret => {
                this.category = ret.data;
            });
        },
        // 删除
        del(applyId) {
            utils.confirm("您确认要删除吗?").then(() => {
                utils.showSpin();
                request.delete("/platform/apply/del/" + applyId, {}).then(r => {
                    utils.success('删除成功').then(() => {
                        this.doRefresh();
                    });
                });
            });
        },
        //获取数据 
        doRefresh() {
            this.param.page = 1;
            this.getList();
        },
        // 获取数据
        getList() {
            this.loading = true;
            utils.showSpin();
            this.http
                .get('/platform/apply/query', this.param)
                .then(ret => {
                    this.data = ret.data;
                    this.loading = false;
                    this.mode = 'list';
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        setDateRange(data, str) {
            this.param.startDate = str[0];
            this.param.endDate = str[1];
        },
        //设置每页的显示条数
        setLimit(current, limit) {
            this.param.page = 1;
            this.param.limit = limit;
            this.getList();
        },
        //页码切换
        getNext(page) {
            this.param.page = page;
            this.getList();
        }
    }
};
</script>

<style scoped></style>
