import { render, staticRenderFns } from "./create-form-model.vue?vue&type=template&id=1350ac92&scoped=true"
import script from "./create-form-model.vue?vue&type=script&lang=js"
export * from "./create-form-model.vue?vue&type=script&lang=js"
import style0 from "./create-form-model.vue?vue&type=style&index=0&id=1350ac92&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1350ac92",
  null
  
)

export default component.exports